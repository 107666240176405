import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AdminTaskDetails = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [error, setError] = useState(null);
  const [revisionComments, setRevisionComments] = useState('');

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const token = sessionStorage.getItem('adminToken');
        if (!token) {
          throw new Error('No admin token found');
        }

        const response = await fetch(`${API_BASE_URL}/api/admin/tasks/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTask(data.task);
        setTaskStatus(data.statusComplete);
      } catch (e) {
        console.error('Failed to fetch task details:', e);
        setError(e.message);
      }
    };

    fetchTaskDetails();
  }, [id]);

  const handleAccept = async () => {
    try {
      const token = sessionStorage.getItem('adminToken');
      if (!token) {
        throw new Error('No admin token found');
      }

      const response = await fetch(`${API_BASE_URL}/api/admin/complete-task/${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert('Task accepted!');
      window.location.href = '/admin/orders';
    } catch (e) {
      console.error('Failed to accept task:', e);
      alert('Failed to accept task.');
    }
  };

  const handleRequestRevision = async () => {
    try {
      const token = sessionStorage.getItem('adminToken');
      if (!token) {
        throw new Error('No admin token found');
      }

      const response = await fetch(`${API_BASE_URL}/api/admin/tasks/${id}/request-revision`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ comments: revisionComments })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert('Revision requested!');
      window.location.href = '/admin/orders';
    } catch (e) {
      console.error('Failed to request revision:', e);
      alert('Failed to request revision.');
    }
  };

  const renderDocuments = (documents) => {
    return (
      <ul className="list-disc pl-5">
        {documents.map((doc, index) => {
          // Extract the upload path for each document individually
          const uploadPath = doc.fileUrl?.match(/\/uploads\/.*$/)?.[0] || '/uploads/';
          
          return (
            <li key={index} className="flex items-center mb-2">
              <FaFileAlt className="text-gray-600 mr-2" />
              <a
                href={`${API_BASE_URL}${uploadPath}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {doc.fileName}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderSubmissionDetails = () => {
    return (
      <div className="mt-6">
        <h3 className="text-xl text-gray-700 font-semibold mb-2">Submission Details</h3>
        <p className="text-gray-700 mb-4">{task.submissionDetails}</p>
        {task.documents && task.documents.length > 0 && (
          <div>
            <h4 className="text-lg text-gray-700 font-semibold mb-2">Submission Files</h4>
            {renderDocuments(task.documents.filter(doc => doc.documentType === 'submission'))}
          </div>
        )}
      </div>
    );
  };

  const renderRevisionDetails = () => {
    return (
      <div className="mt-6">
        <h3 className="text-xl text-gray-700 font-semibold mb-2">Revision History</h3>
        {task.revisions.map((revision, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-100 rounded">
            <h4 className="text-lg text-gray-700 font-semibold mb-2">Revision #{index + 1}</h4>
            <p className="text-gray-700 mb-2">{revision.description}</p>
            <p className="text-gray-600 mb-2">Revised by: {revision.revisedBy}</p>
            {revision.documents && revision.documents.length > 0 && (
              <div>
                <h5 className="text-md text-gray-700 font-semibold mb-2">Revision Files</h5>
                {renderDocuments(revision.documents)}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!task) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:w-64 bg-teal-500 text-gray-100 flex flex-col">
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">Writers Admin</h1>
          <nav>
            <Link to="/admin/dashboard" className="block py-2 hover:bg-gray-700">Dashboard</Link>
            <Link to="/admin/tasks" className="block py-2 hover:bg-gray-700">Manage Tasks</Link>
            <Link to="/admin/writers" className="block py-2 hover:bg-gray-700">My Writers</Link>
            <Link to="/admin/orders" className="block py-2 hover:bg-gray-700">Orders</Link>
            <Link to="/admin/bids" className="block py-2 hover:bg-gray-700">Bids</Link>
            <Link to="/admin/transactions" className="block py-2 hover:bg-gray-700">Transactions</Link>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-teal-300 shadow-sm z-10">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="font-semibold text-xl text-gray-600">Task Details</h2>
          </div>
        </header>

        {/* Task Details Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-600">
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-2xl text-gray-800 font-semibold mb-4">{task.title}</h2>
              <p className="text-gray-700 mb-4">{task.description}</p>
              <p className="text-gray-700 mb-4">Price: KSh {task.price}</p>
              <p className="text-gray-700 mb-4">Deadline: {new Date(task.deadline).toLocaleDateString()}</p>
              <p className={`text-gray-700 mb-4 ${getStatusColor(task.status)}`}>Status: {task.status}</p>

              {task.documents && task.documents.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-xl text-gray-700 font-semibold mb-2">Task Files</h3>
                  {renderDocuments(task.documents.filter(doc => doc.documentType === 'task support'))}
                </div>
              )}

              {renderSubmissionDetails()}
              <div className="mt-6">
                <h3 className="text-xl text-gray-700 font-semibold mb-2">Request Revision</h3>
                <textarea
                  className="w-full p-2 mb-4 bg-gray-800 text-gray-100 rounded"
                  rows="4"
                  value={revisionComments}
                  onChange={(e) => setRevisionComments(e.target.value)}
                  placeholder="Enter revision comments..."
                ></textarea>
              </div>
              {renderRevisionDetails()}

              <div className="mt-6 flex space-x-4">
                {taskStatus !== 'completed' && (
                  <button 
                  onClick={handleAccept} 
                  className="p-3 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
                  >
                    Close Task
                  </button>
                )}
                {taskStatus !== 'completed' && (
                  <button 
                    onClick={handleRequestRevision} 
                    className="p-3 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition duration-300"
                  >
                    Request Revision
                  </button>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'open':
      return 'text-green-600';
    case 'in_progress':
      return 'text-yellow-600';
    case 'completed':
      return 'text-blue-600';
    case 'disputed':
      return 'text-red-600';
    case 'cancelled':
      return 'text-gray-600';
    default:
      return '';
  }
};

export default AdminTaskDetails;